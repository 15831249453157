<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Targets Setup</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <!-- start of toolbar 2 -->
      <v-toolbar color="secondary" flat dense>
        <v-btn class="mr-1" to="/new-target" outlined color="toolbarIcon" text>
          <v-icon left dark>mdi-plus</v-icon>New
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          flat
          outlined
          solo
          dense
          label="search"
          style="width: 150px"
        ></v-text-field>
      </v-toolbar>
      <!-- end of toolbar 2 -->

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :loading="loading"
              loading-text="Loading... Please wait"
              :headers="headers"
              :items="targets"
              :search="search"
            >
              <!-- Close time template -->
              <template v-slot:item.RecurPat="props">
                <span v-if="props.item.RecurPat == 'N'">Never</span>
                <span v-if="props.item.RecurPat == 'D'">Daily</span>
                <span v-if="props.item.RecurPat == 'Q'">Quarterly</span>
                <span v-if="props.item.RecurPat == 'M'">Monthly</span>
                <span v-if="props.item.RecurPat == 'A'">Annually</span>
              </template>
              <!-- end -->
              <!-- Close time template -->
              <template v-slot:item.Status="props">
                <span v-if="props.item.Status == 'O'">Open</span>
                <span v-if="props.item.Status == 'C'">Closed</span>
              </template>
              <!-- end -->
              <!-- skus link -->
              <template v-slot:item.ids="{ item }">
                <v-btn color="flatButton" text :to="`/targets/${item.ids}`"
                  >Skus</v-btn
                >
              </template>
              <!-- end of skus link -->

              <!-- sales employee template -->
              <template v-slot:item.employees="props">
                <v-btn
                  color="flatButton"
                  text
                  :to="`/sales/employee/${props.item.employees.id}`"
                  >{{ props.item.employees.SlpName }}</v-btn
                >
              </template>
              <!-- end -->
              <!-- sales employee template -->
              <template v-slot:item.id="{ item }">
                <v-btn color="flatButton" text :to="`/target/${item.id}`">
                  <v-icon color="green">mdi-eye</v-icon></v-btn
                >
              </template>
              <!-- end -->
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <snackbar refs="snackbar"></snackbar>
  </v-container>
</template>
  
  <script>
export default {
  data() {
    return {
      loading: false,
      search: null,
      targets: [],
      headers: [
        { text: "Description", value: "Comment" },
        { text: "Target Value", value: "document_lines[0].Tvalue" },
        { text: "From Date", value: "TfromDate" },
        { text: "To Date", value: "TtoDate" },
        { text: "Recurring", value: "RecurPat" },
        { text: "Status", value: "Status" },
        { text: "Action", value: "id" },
      ],
    };
  },
  methods: {
    getTargets() {
      const self = this;
      this.loading = true;
      this.$store
        .dispatch("get", `/targets`)
        .then((res) => {
          console.log(res, "targets response");
          self.targets = res.ResponseData;
          self.loading = false;
        })
        .catch((err) => {
          this.$refs.snackbar.show(err, "red");
        });
    },
  },
  created() {
    this.getTargets();
  },
};
</script>
  
  <style scoped>
</style>